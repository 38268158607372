<template>
  <div class="for_got_pass">
    <div class="headers">
      <div class="head_wrap">
        <div class="head">欢迎来到校园云采</div>
      </div>
    </div>

    <div class="tabs_bg">
      <div class="title">供应商后台管理系统</div>
    </div>

    <div class="main">
      <el-button type="primary" icon="el-icon-arrow-left" plain @click="$router.push('login')">返回</el-button>

      <div class="steps">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step title="验证手机号码"></el-step>
          <el-step title="重新设置密码"></el-step>
          <el-step title="修改成功"></el-step>
        </el-steps>
      </div>

      <div class="form" v-show="active == 0">
        <el-form ref="form1" :model="form1" label-width="120px" :rules="rules1">
          <el-form-item label="手机号码" prop="mobile">
            <el-input v-model="form1.mobile" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="短信验证码" prop="SMScode">
            <el-input v-model="form1.SMScode" placeholder="请输入短信验证码" maxlength="6">
              <el-button class="send" slot="suffix" type="primary" :disabled="!canSendCode" :loading="sendLoading" @click="getSMSCode">{{ sendCodeBtnText }}</el-button>
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="form" v-show="active == 1">
        <el-form ref="form2" :model="form2" label-width="120px" :rules="rules2">
          <el-form-item label="新的密码" prop="password">
            <el-input v-model="form2.password" placeholder="请输入新的密码"></el-input>
            <el-tooltip content="密码至少8位数且必须包含大写字母、小写字母、数字" placement="top" effect="light">
              <i class="el-icon-warning-outline tips"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="form2.confirmPassword" placeholder="请输入再次输入密码"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="form" v-show="active == 2">
        <el-result icon="success" title="修改成功" subTitle="您的密码已成功修改">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="$router.replace('/login')">去登录</el-button>
          </template>
        </el-result>
      </div>

      <div class="btn">
        <el-button v-show="active == 1" type="primary" @click="handleLast">上一步</el-button>
        <el-button v-show="active < 2" type="primary" @click="handleNext" :loading="btnLoading">{{ active == 0 ? "下一步" : "确定修改" }}</el-button>
      </div>
    </div>

    <footers />
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      sendLoading: false,
      btnLoading: false,
      canSendCode: true,
      countdown: 60,
      sendCodeBtnText: "发送验证码",

      form1: {
        mobile: "",
        SMScode: "",
      },
      form2: {
        password: "",
        confirmPassword: "",
      },
      rules1: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: "手机号码有误", trigger: "blur" },
        ],
        SMScode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
          { pattern: /^[0-9]{6}$/, message: "验证码为6位数", trigger: "blur" },
        ],
      },
      rules2: {
        password: [
          { required: true, message: "请输入新的登录密码", trigger: "blur" },
          // {
          //   min: 8,
          //   message: "密码格式不正确",
          //   validator: (rule, value, callback) => {
          //     if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value)) {
          //       callback(new Error("密码必须包含大写字母、小写字母、数字"))
          //     } else {
          //       callback()
          //     }
          //   },
          //   trigger: ["blur", "change"],
          // },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form2.password) {
                callback(new Error("两次输入的密码不一致!"))
              } else {
                callback()
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
    }
  },
  created() {},
  methods: {
    getSMSCode() {
      if (this.form1.mobile == "") {
        this.$message.error("手机号码不能为空")
      } else {
        if (!this.canSendCode) return

        this.sendLoading = true
        this.$axios
          .get(this.$api.SMSCode, {
            params: {
              mobile: this.form1.mobile,
            },
          })
          .then(res => {
            if (res.data.code == 100) {
              this.sendLoading = false
              this.startCountdown()
              this.$message.success("验证码已发送，请查收短信")
            } else {
              this.$message.error(res.data.desc)
              this.sendLoading = false
            }
          })
          .catch(() => {
            this.sendLoading = false
            this.$message.error("发送验证码失败，请稍后再试")
          })
      }
    },
    startCountdown() {
      this.canSendCode = false
      this.countdown--

      if (this.countdown > 0) {
        setTimeout(this.startCountdown, 1000)
        this.sendCodeBtnText = `${this.countdown}秒后重发`
      } else {
        this.sendCodeBtnText = "发送验证码"
        this.canSendCode = true
        this.countdown = 60
      }
    },
    handleLast() {
      if (this.active-- < 0) this.active = 0
      this.form2 = {
        password: "",
        confirmPassword: "",
      }
    },
    // 验证验证码
    CheckSMSCode() {
      this.btnLoading = true
      this.$axios
        .get(this.$api.CheckSMSCode, {
          params: {
            mobile: this.form1.mobile,
            SMScode: this.form1.SMScode,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.active = 1
          }
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    // 修改密码
    changePassword() {
      this.btnLoading = true
      this.$axios
        .put(this.$api.retrievePassword, {
          telphone: this.form1.mobile,
          password: this.form2.password,
        })
        .then(res => {
          if (res.data.code == 100) {
            this.active = 2
          }
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    handleNext() {
      let ac = this.active
      switch (ac) {
        case 0:
          this.$refs.form1.validate(valid => {
            if (valid) {
              this.CheckSMSCode()
            } else {
              this.$message.error("手机号或验证码有误")
              return false
            }
          })
          break
        case 1:
          this.$refs.form2.validate(valid => {
            if (valid) {
              this.changePassword()
            } else {
              this.$message.error("密码格式不正确")
              return false
            }
          })
          break
      }
    },
  },
}
</script>

<style scoped lang="scss">
.for_got_pass {
  height: 100%;

  .headers {
    display: flex;
    justify-content: right;
    width: 100%;
    height: 44px !important;
    background-color: #ebfffb;

    .head_wrap {
      width: 280px;
      height: 44px;
      padding-right: 25px;
      background: url(../assets/img/user.png) no-repeat;
      background-size: cover;
      box-sizing: border-box;

      .head {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 44px;
        color: #fff;
        font-size: 16px;
        margin: 0 auto;
      }
    }
  }

  .tabs_bg {
    position: relative;
    width: 100%;
    height: 130px;
    background: url(../assets/img/head.png) no-repeat;
    background-size: cover;
    background-position: 80%;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: 76px;
      line-height: 76px;
      color: #fff;
      font-size: 28px;
      font-family: "zzgf";
      text-align: center;
    }
  }

  .main {
    width: 100%;
    max-width: 1800px;
    height: 600px;
    margin: 20px auto;
    padding: 10px 20px 20px;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    background-color: #fff;
    box-sizing: border-box;

    .steps {
      width: 900px;
      margin: 0 auto 60px;
      padding-bottom: 60px;
      border-bottom: 1px solid #f0f1f1;
    }

    .form {
      width: 450px;
      margin: 0 auto;

      .send {
        width: 120px;
        transform: translateX(5px);
      }

      .tips {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
        color: #c0c4cc;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .btn {
      text-align: center;
      padding-top: 30px;
    }
  }
}
</style>
